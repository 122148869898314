import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import {Container, Row} from 'reactstrap'
import PageHeader from '../components/pageHeader'
import PageStyling from '../components/pageStyling'
import Post from '../components/post'

export default ({data}) => (
  <Layout title="Blog">
    <PageHeader title="Blog"/>
    <Container className="py-5">
      <PageStyling>
        <h2 className="text-primary">OUR BLOG</h2>
        <p>
          If you’re keen to learn more about working in Australia, why not enquire today and start the ball rolling?
          The way we work is a lot different to conventional recruiters. We know first hand that this is a massive decision and we’re also aware of the perceived complexities of emigrating abroad. It is for this reason we aim to provide a highly personalised service. We consider ourselves as consultants or partners working with you by simplifying the process so as to ensure you avoid any potential pitfalls.
        </p>
      </PageStyling>
      <Row>
        {data.allWordpressPost.edges.map((post, i) => (
          <Post post={post} key={i}/>
        ))}
      </Row>
    </Container>
  </Layout>
)


export const pageQuery = graphql`
  query {
    allWordpressPost(filter: {tags: {eq: 49}}) {
      edges {
        node {
          title
          excerpt
          path
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fixed(height: 250, width: 400) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
